import * as Sentry from '@sentry/gatsby'

import { GIT_HASH, RELEASE_ENV, SENTRY_DSN, DISABLE_SENTRY } from './src/config'

if (RELEASE_ENV && RELEASE_ENV !== 'development' && !DISABLE_SENTRY) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: RELEASE_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    release: GIT_HASH,
    sampleRate: 1,
    tracesSampleRate: 1,
  })
}
